//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Axios from 'axios'
export default {
    data() {
        return {
            sent: false,
            p_iva: '',
            loading: false
        }
    },
    methods: {
        submit() {
            Axios.post(process.env.VUE_APP_API_ROOT + '/mail/' + this.p_iva)
                .then(() => {
                    this.sent = true
                })
        }
    }
}
